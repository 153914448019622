import { MasterChannel } from "@/components/MasterMixer"
import { LocalAction, localReducer } from "@/reducers/localReducer"
import { PlayerAction, playerReducer } from "@/reducers/playerReducer"
import {
    Agent,
    Bonza,
    BonzaService,
    LocalDevice,
    LocalRemoteManager,
} from "@/services/BonzaService"
import LoggerService, { LogLevel } from "@/services/LoggerService"
import { NwkInfo } from "@/services/NwkInfoService"
import { getUserSessions } from "@/services/UserService"
import { Video } from "@/services/VideoService"
import {
    inputChannelCountPossibilities,
    RemoteInfoData,
    setReceiverBufferSizeMessage,
    setScreenSharingMessage,
    StartStreamMessage,
    stopStreamMessage,
} from "@/types/AppMessage"
import {
    BonzaSessionResponse,
    BonzaSessionUpdatedProps,
} from "@/types/BonzaSession"
import {
    ConnectionResource,
    ConnectionWithUserResource,
} from "@/types/Connection"
import {
    AudioInitialisationSequenceStates,
    DeviceChangeEventType,
    IDevice,
} from "@/types/Device"
import {
    PlayerViewLocal,
    PlayerViewRemote,
    RTInfo,
    RTInfoEmptyField,
} from "@/types/PlayerView"
import {
    RemoteConnectState,
} from "@/types/XRemoteManager"
import { UserInvitationNotification } from "@/types/User"
import { User } from "@/types/UserClass"
import {
    MessageEventListener,
    ReadyState,
} from "@/types/WebSocket"
import { router } from "@inertiajs/react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { enqueueSnackbar, SnackbarProvider } from "notistack"
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react"
import axios from "axios"
import { AgentService } from "@/services/AgentService"
import {
    getBonzaSessionPeers,
    postJoinBonzaSession,
    postLeaveBonzaSession,
    putConnect,
} from "@/services/ConnectionService"

type BonzaContextProps = {
    getConnection: () => ConnectionResource | undefined
    updateConnection: (connection: ConnectionResource) => void
    user: User | undefined
    setUser: Dispatch<User | undefined>
    peers: ConnectionWithUserResource[] | undefined
    players: PlayerViewRemote[]
    setPlayers: Dispatch<PlayerAction>
    locals: PlayerViewLocal[]
    setLocals: Dispatch<LocalAction>
    setInputChannelCount: (count: number) => void
    hideLocals: boolean
    avatarAzimuth: number
    screenSharing: boolean
    toggleScreenSharing: () => void
    connected: boolean
    skipSetup: boolean
    setSkipSetup: Dispatch<boolean>
    setupBackRoute: string
    joinBonzaSession: (
        sessionId: string
    ) => Promise<ConnectionResource> | undefined
    leaveBonzaSession: () => Promise<ConnectionResource> | undefined
    findPeer: (
        ip: string,
        port: number | string
    ) => ConnectionWithUserResource | undefined
    getPeer: (identifier: string) => ConnectionWithUserResource | undefined
    getPeerById: (id: number) => ConnectionWithUserResource | undefined
}

const BonzaContext = createContext<BonzaContextProps | undefined>(undefined)

export const BonzaContextProvider = ({
    children,
    connection,
}: PropsWithChildren<{
    connection: ConnectionResource | undefined
}>) => {
    const logger = new LoggerService("BonzaContext", LogLevel.NonTrivial)
    const connectionRef = useRef<ConnectionResource | undefined>(connection)
    const peersRef = useRef<Record<number, ConnectionWithUserResource> | undefined>({})
    const updateConnection = (connection: ConnectionResource) => {
        connectionRef.current = connection

        if (connection)
            putConnect({
                identifier: connection.identifier,
                engine_ip: connection.engine_ip,
                interface_ip: connection.interface_ip,
                //tcp_port: connection.tcp_port,
                udp_port: connection.udp_port,
                udp_port_2: `${connection.udp_port} (${connection.port_status})`,
                port_status: connection.port_status,
                metadata: JSON.stringify(connection.metadata),
            })
    }

    const getConnection = () => {
             return connectionRef.current
    }

    const {data: {peers, peersByIpPort, peersByIdentifier, peersById}, refetch: fetchPeers } = useQuery({
        queryKey: ["peers"],
        queryFn: async () => {
            const peers = await getBonzaSessionPeers(`${getConnection()?.id}`)
            const peersByIpPort: Record<string, ConnectionWithUserResource> = {}
            const peersByIdentifier: Record<string, ConnectionWithUserResource> = {}
            const peersById: Record<number, ConnectionWithUserResource> = {}

            if (peers)
                for (const peer of peers) {
                    const isOnLocalNetwork = peer.engine_ip === connection?.engine_ip

                    peersByIpPort[`${isOnLocalNetwork ? `${peer.interface_ip}` : `${peer.engine_ip}`}:${peer.tcp_port}`] = peersByIdentifier[
                        `${peer.identifier}`
                        ] = peersById[peer.id] = peer
                }
            peersRef.current = peersById
            return {peers, peersByIpPort, peersByIdentifier, peersById}
        },
        initialData: {
            peers: [],
            peersByIpPort: {},
            peersByIdentifier: {},
            peersById: {},
        },
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const findPeer = (ip: string, port: number | string) => {
        try {
            return peersByIpPort[`${ip}:${port}`]
        }
        catch { return undefined }
    }

    const getPeer = (identifier: string) => {
        try {
            return peersByIdentifier[identifier]
        } catch { return undefined }
    }

    const getPeerById = (id: number) => {
        const peer = peersRef && peersRef.current ? peersRef.current[id] : undefined
        if(peer === undefined) logger.warn(`getPeerById ${id} not found, available peers: ${JSON.stringify(peersRef?.current)}`)

        return peer
    }

    const joinBonzaSession = (sessionId: string) => {
        const connectionId = getConnection()?.id.toString()
        if (sessionId && connectionId) {
            const promise = postJoinBonzaSession(connectionId, sessionId)

            promise.then(() =>
                fetchPeers().then(({data}) => {
                    if(data?.peers && data.peers.length > 0)
                    for (const remote of data.peers) {
                        connect(remote)
                    }
                })
            )

            return promise
        }
    }

    const leaveBonzaSession = () => {
        const connection = getConnection()
        if (connection && connection.bonza_session_id) {
            const promise = postLeaveBonzaSession(`${connection.id}`)

            promise.then(() =>
                peers?.map((remote) => disconnect(remote))
            )

            return promise
        }
    }

    const [user, setUser] = useState<User | undefined>(undefined)

    const [players, setPlayers] = useReducer(
        playerReducer,
        new Array<PlayerViewRemote>()
    )

    const connected = useMemo(
        () => Agent.readyState == ReadyState.Open,
        [Agent.readyState]
    )

    /******************************************************************************************************************
     * Listen to the agent
     */

    /******************************************************************************************************************
     * Talk to the agent
     */

    const [locals, setLocals] = useReducer(localReducer, [MasterChannel])

    const [hideLocals, setHideLocals] = useState(
        !LocalDevice.activeInputSoundCard
    )

    const [avatarAzimuth, setAvatarAzimuth] = useState(0)

    const setInputChannelCount = (count: number) => {
        const currentCount = locals.length - 1

        if (count < currentCount) {
            setLocals({
                action: "TRUNCATE",
                newLen: count + 1,
            })
        } else if (count > currentCount) {
            const newLocals = []
            for (let i = currentCount; i < count; i++) {
                newLocals.push({
                    identifier: `Channel ${i + 1}`,
                    agent: Agent,
                    channel: i,
                })
            }
            setLocals({
                action: "CREATE",
                playerArr: newLocals,
            })
        }
    }

    const [screenSharing, setScreenSharing] = useState(false)

    const toggleScreenSharing = () => {
        if (!screenSharing) {
            Agent.send(new setScreenSharingMessage(true))
            setScreenSharing(true)
        } else {
            Agent.send(new setScreenSharingMessage(false))
            setScreenSharing(false)
        }
    }

    const agentMessageListener: MessageEventListener = {
        handleMessageEvent(_: MessageEvent, message: any | null | undefined) {
            if (!message || !message.type) return

            switch (message.type) {
                case "tellPort": {
                    const connection = getConnection()
                    if (connection) {
                        if (
                            connection.udp_port !== message.localPort ||
                            //connection.tcp_port !== message.localBindPort ||
                            connection.udp_port_2 !== message.localPort2 ||
                            connection.engine_ip !== message.localIP ||
                            connection.interface_ip !== message.interfaceIP ||
                            connection.port_status !== message.NAT
                        ) {
                            updateConnection({
                                ...connection,
                                udp_port: message.localPort,
                                //tcp_port: message.localBindPort,
                                engine_ip: message.localIP,
                                interface_ip: message.interfaceIP,
                                port_status: message.NAT,
                                metadata: {
                                    ...(connection.metadata ?? {}),
                                    os: message.OS,
                                    internalPort: AgentService.getAgentPort(),
                                },
                            })
                        }
                    }
                    break
                }
                case "sendVideoImage":
                    if (Video.shouldUpdate("__SELF"))
                        Video.set(
                            "__SELF",
                            `data:image/jpeg;base64,${message.jpgBuffer}`
                        )
                    break

                case "sendRemoteVideoImage":
                case "sendRemoteSoundLevel": {
                    /* also trap setRemoteSoundLevel here in case arrays out of step
                      TODO - if works refactor this out to sep function
                    */
                    let idStr: string
                    let isVideo: boolean = false
                    let IP: string
                    let port: string
                    if (message.type == "sendRemoteVideoImage") {
                        // 240205+ new BonzaApp message for remote video:
                        idStr = message.ID
                        IP = message.socketIP
                        port = message.portTCP
                        isVideo = true
                    } else {
                        //data1, data2, data3, data4
                        //ID,    value, IP,    port
                        idStr = message.data1
                        IP = message.data3
                        port = message.data4
                    }

                    try {
                        console.log(idStr)
                        console.log("Peers when message received", peersRef.current)
                        const id = Number(idStr)
                        const peer = getPeerById(id)
                        if (peer) {
                            if (isVideo && Video.shouldUpdate(peer.identifier)) {
                                Video.set(
                                    peer.identifier,
                                    `data:image/jpeg;base64,${message.jpgBuffer}`
                                )
                            }
                        }
                        break
                    } catch {
                        logger.log(`ID is not a number ${message.ID ?? message.data1}`)
                    }
                }

                // just handle self & any remotes' VIDEO here
                // - other messages eg setRemoteSoundLevel etc handled elsewhere
                case "tellLatency":
                    {
                        // Agent sending curr latency for display
                        const idStr = message.data1
                        const info: RTInfo = {
                            latency: message.data2,
                            dropout: RTInfoEmptyField,
                        }

                        if (idStr) NwkInfo.set(idStr, info)
                    }
                    break
                case "tellDropout":
                    {
                        // Agent sending curr dropout for display
                        const idStr = message.data1
                        const info: RTInfo = {
                            dropout: `${message.data2}`,
                        }
                        const logDropout: boolean = false
                        if (logDropout) {
                            logger.info(
                                `Stage:tellDropout ${idStr} ${info.dropout}`
                            )
                        }
                        if (idStr) {
                            NwkInfo.set(idStr, info)
                        }
                    }
                    break
                case "setJitterBuffer": {
                    // Agent sending curr jitter suggestion - we dont have to do it if already in 'manual' mode
                    const info: RTInfo = {
                        jitter: message.newJBValue,
                        dropout: RTInfoEmptyField,
                    }
                    if (LocalDevice.isAutoJB()) {
                        NwkInfo.set(message.ID, info)
                        Agent.send(
                            new setReceiverBufferSizeMessage(
                                message.ID,
                                message.newJBValue
                            )
                        )
                    } else {
                        // manual jb - just use the (global) manual one of this remote
                        info.jitter =
                            LocalDevice.deviceDataStore.advancedSettings.manualJitterIndex
                        NwkInfo.set(message.ID, info)
                    }
                    break
                }
                case "headTracker": {
                    const azimuth = message.azimuth
                    if (azimuth === undefined || typeof azimuth !== "number")
                        logger.log("Invalid azimuth message")
                    else setAvatarAzimuth(azimuth)
                    break
                }
                default: {
                    logger.log("No valid message")
                    break
                }
            }
        },
    }

    const stageDeviceChangeListener = {
        handleDeviceChange(device: IDevice, eventType: DeviceChangeEventType) {
            switch (eventType) {
                case DeviceChangeEventType.ActiveSoundCard: {
                    const sc = device.activeInputSoundCard
                    if (!sc) {
                        setHideLocals(true)
                        return
                    }
                    if (hideLocals) setHideLocals(false)
                    const scInsCount = sc.inputChannels
                    if (!scInsCount || scInsCount == 0) {
                        return
                    }
                    const inUseCount =
                        inputChannelCountPossibilities[
                            Number(
                                LocalDevice.getSavedSettings().inChannelsIndex
                            )
                        ]
                    if (!inUseCount || inUseCount == 0) {
                        return
                    }
                }
            }
        },
    }

    const queryClient = useQueryClient()

    const { data: dataSessions } = useQuery<BonzaSessionResponse[]>({
        queryKey: ["sessions"],
        queryFn: getUserSessions,
        enabled: user !== undefined,
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const enteredSession = (remote: ConnectionWithUserResource) => {
        const connection = connectionRef.current
        if (connection?.id === remote?.id) return
        if (
            connection?.bonza_session_id &&
            connection.bonza_session_id === remote.bonza_session_id
        ) {
            enqueueSnackbar(`User joined our session: ${remote.user.name}`)
            connect(remote)
        } else {
            enqueueSnackbar(`User joined: ${remote.user.name}`)
        }

        fetchPeers()
    }

    const leftSession = (remoteConnection: ConnectionWithUserResource) => {
        const connection = getConnection()
        if (connection?.id === remoteConnection?.id) return
        enqueueSnackbar(`User left: ${remoteConnection.user.name}`)
        disconnect(remoteConnection)

        fetchPeers()
    }

    const connect = (remote: ConnectionWithUserResource) => {
        if (!(remote.ip && remote.tcp_port)) return
        const connection = getConnection()
        if (!connection) return
        const isOnLocalNetwork = remote.engine_ip === connection.engine_ip
        const params: RemoteInfoData = {
            IP: isOnLocalNetwork
                ? `${remote.interface_ip}`
                : `${remote.engine_ip}`,
            port: isOnLocalNetwork ? "50050" : `${remote.udp_port!}`,
            ownID: connection.id,
            remoteSenderID: remote.id,
            currentGroupPosition: 0,
            remoteNAT: `${connection.udp_port} (${connection.port_status})`,
            remotePortTCP: `${connection.tcp_port}`,
            remoteSocketIP: `${connection.engine_ip}`,
        }

        Agent.send(new StartStreamMessage(params))

        // const player: PlayerViewRemote = {
        //     id: remote.id,
        //     identifier: remote.identifier,
        //     agent: Agent,
        //     ip: remote.ip,
        //     port: `${remote.udp_port}`,
        //     readiness: RemoteConnectState.verified,
        //     pan: 0,
        // }
        // setPlayers({
        //     action: "CREATE",
        //     player: player,
        // })
        enqueueSnackbar(`You've managed to connect to ${remote.user.name}`, {
            variant: "success",
        })
    }

    const disconnect = (remote: ConnectionWithUserResource) => {
        if (!(remote.id)) return

        const disconnectMsg = new stopStreamMessage(
            `${remote.id}`,
        )

        Agent.send(disconnectMsg)

        // setPlayers({
        //     action: "DELETE",
        //     partialPlayer: {
        //         id: remote.id,
        //     },
        // })
        enqueueSnackbar(`You've managed to disconnect from ${remote.user.name}`, {
            variant: "error",
        })
    }

    const sessionUpdateCallback = async (data: BonzaSessionUpdatedProps) => {
        if (data.activated_at) {
            enqueueSnackbar(`Session started: ${new Date(data.activated_at)}`)
        } else if (data.deactivated_at) {
            enqueueSnackbar(`Session ended: ${new Date(data.deactivated_at)}`)
            fetchPeers()
        } else if (data.joined) {
            enteredSession(data.joined)
        } else if (data.left) {
            leftSession(data.left)
        } else {
            enqueueSnackbar(`Session updated: ${data.name}`)
        }
    }

    useEffect(() => {
        if (user?.id !== undefined) {
            dataSessions?.forEach((session) => {
                window.Echo.private(`Bonza.Session.${session.id}`)
                    .error((error: unknown) => console.error(error))
                    .subscribed(() =>
                        console.log(`Subscribed to ${session.id}`)
                    )
                    .listen("BonzaSessionUpdated", sessionUpdateCallback)
            })

            window.Echo.private(`App.Models.User.${user.id}`).notification(
                async ({
                    bonza_session,
                    invited_by,
                }: UserInvitationNotification) => {
                    enqueueSnackbar(
                        `You have been invited to ${bonza_session.name} by ${invited_by.name}`
                    )
                    await queryClient.invalidateQueries({
                        queryKey: ["invites"],
                    })
                }
            )
        }
    }, [dataSessions])

    const [remoteListenerReady, setRemoteListenerReady] = useState(false)
    const [deviceListenerReady, setDeviceListenerReady] = useState(false)

    useEffect(() => {
        if (user !== undefined) {
            if (BonzaService.user === null) BonzaService.user = user

            const heartbeat = setInterval(async () => {
                axios.get(route("api.connection.heartbeat")).catch((error) => {
                    logger.warn(`Connection heartbeat failed: ${error}`)
                    //TODO - handle failure here!
                })
            }, 10000)

            const count =
                inputChannelCountPossibilities[
                    Number(LocalDevice.getSavedSettings().inChannelsIndex)
                ]
            setInputChannelCount(count)

            Bonza.join()

            if (Agent.readyState !== ReadyState.Open) {
                Agent.addMessageListener(agentMessageListener)
                Agent.connect()
            }

            // if (!remoteListenerReady) {
            //     LocalRemoteManager.addRemoteChangeListener(
            //         stageRemoteChangeListener
            //     )
            //     setRemoteListenerReady(true)
            // }

            if (!deviceListenerReady) {
                LocalDevice.addDeviceChangeListener(stageDeviceChangeListener)
                setDeviceListenerReady(true)
            }

            return () => {
                clearTimeout(heartbeat)
                Bonza.leave()
            }
        }
    }, [user])

    const [skipSetup, setSkipSetup] = useState(false)
    const [setupBackRoute, setSetupBackRoute] = useState(route("dashboard"))

    useEffect(() => {
        if (
            user &&
            LocalDevice.initState & AudioInitialisationSequenceStates.AISS_3 &&
            (LocalDevice.activeInputSoundCard === null ||
                LocalDevice.activeOutputSoundCard === null)
        ) {
            if (!skipSetup) {
                setSetupBackRoute(route("dashboard"))
                router.get(route("firstsetup"))
            } else if (route().current() === "stage") {
                setSetupBackRoute(route("stage"))
                router.get(route("firstsetup"))
            }
        }
    }, [user, LocalDevice.initState])

    useEffect(() => {
        // Remove players
        if(!peersRef.current) return

        const removePlayers = players.filter(
            (player) => getPeerById(player.id) === undefined
        )
        removePlayers.forEach((player) =>
            setPlayers({
                action: "DELETE",
                partialPlayer: {
                    id: player.id,
                },
            })
        )

        // Add new players
            const addPeers = Object.values<ConnectionWithUserResource>(peersRef.current).filter(
                (peer) =>
                    players.findIndex(
                        (player) => player.id === peer.id
                    ) === -1
            )
            addPeers.forEach((peer) =>
                setPlayers({
                    action: "CREATE",
                    player: {
                        id: peer.id,
                        identifier: peer.identifier,
                        agent: Agent,
                        ip: `${peer.engine_ip}`,
                        port: `${peer.udp_port}`,
                        readiness: RemoteConnectState.verified,
                        pan: 0,
                    },
                })
            )

    }, [peersRef.current])

    return (
        <BonzaContext.Provider
            value={{
                getConnection,
                updateConnection,
                players,
                setPlayers,
                peers,
                user,
                setUser,
                locals,
                setLocals,
                setInputChannelCount,
                hideLocals,
                avatarAzimuth,
                screenSharing,
                toggleScreenSharing,
                connected,
                skipSetup,
                setSkipSetup,
                setupBackRoute,
                joinBonzaSession,
                leaveBonzaSession,
                findPeer,
                getPeer,
                getPeerById,
            }}
        >
            <SnackbarProvider maxSnack={5} disableWindowBlurListener={true}>
                {children}
            </SnackbarProvider>
        </BonzaContext.Provider>
    )
}

export function useBonzaContext(): BonzaContextProps {
    const ctx = useContext(BonzaContext)
    if (ctx === undefined) throw Error("Context is undefined")
    return ctx
}

export default BonzaContext
